import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/body/itch/body-itch-hero.webp";
import productInfoIcon from "images/icons/icon-itch-condition.png";
import productInfoImg from "images/body/itch/eucerin-itch-cream-front.webp";
import img1 from "images/callouts/cout-itch-calming.webp";
import img2 from "images/callouts/cout-itch-lotionrelief.webp";
import img4 from "images/callouts/cout-footer-eczema.webp";
import img5 from "images/callouts/cout-footer-aquaphor.webp";
import img6 from "images/callouts/cout-footer-dry-skin-therapy.webp";

import "./itch.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function IndexPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-body-itch"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Itch"
        pageDescription="Learn about Eucerin and Aquaphor therapeutic solutions for itchy skin"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#8399BA"
            headText="ITCH"
            infoText="Two effective options to help control itch"
            bodySpanText="The therapeutic goal of itch treatment&nbsp;"
            bodyText="is to provide relief, as well as support the skin in rebuilding its natural barrier."
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Icon for therapeutic goal of itch treatment"
            productInfoImg={productInfoImg}
            productInfoImgAlt="Itch Relief Lotion Image"
          >
            <h5 className="weldon-blue">Approaches to help patients stop scratching</h5>
            <p>
              Whether patients are experiencing dry, itchy skin or minor eczema
              flares and skin irritations, these products can help manage itch.
              Recommend each unique formulation for immediate and long-lasting
              relief, plus skin barrier repair properties.
            </p>
          </CategoryDetails>
        </section>
        <section className="callouts-container">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Itch Relief Intensive Calming Lotion`}
                  head1Css="weldon-blue"
                  buttonText="See product information"
                  buttonUrl="/body/itch/eucerinitchrelieflotion"
                  className="center-v"
                >
                  Helps immediately relieve and calm dry, itchy skin
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Eucerin Itch Relief Intensive Calming Lotion"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Aquaphor<sup>&reg;</sup> Itch Relief Ointment`}
                  head1Css="weldon-blue"
                  buttonText="See product information"
                  buttonUrl="/body/itch/aquaphoritchreliefointment"
                  className="center-v"
                >
                  With 1% hydrocortisone, soothes and relieves itch associated
                  with eczema flares and skin irritations
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Aquaphor Itch Relief Ointment"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Eczema Regimen"
                  caption="Choose a proactive therapeutic
                  regimen for eczema"
                  buttonText="Eczema Regimen"
                  buttonUrl="/body/eczema"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Dry Skin Therapy"
                  caption="Replenish what nature provided for dry skin"
                  buttonText="Dry Skin Therapy"
                  buttonUrl="/body/dryskin"
                  imgStyles={{ height: 125 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Dry, Damaged Skin"
                  caption="Take the Aquaphor<sup>®</sup> Approach to help heal dry, damaged skin"
                  buttonText="Dry, Damaged Skin"
                  buttonUrl="/body/damagedskin"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;
